exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-board-members-js": () => import("./../../../src/pages/board-members.js" /* webpackChunkName: "component---src-pages-board-members-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-teacher-resource-kit-js": () => import("./../../../src/pages/teacher-resource-kit.js" /* webpackChunkName: "component---src-pages-teacher-resource-kit-js" */),
  "component---src-pages-theatre-for-all-js": () => import("./../../../src/pages/theatre-for-all.js" /* webpackChunkName: "component---src-pages-theatre-for-all-js" */),
  "component---src-template-single-event-js": () => import("./../../../src/template/single-event.js" /* webpackChunkName: "component---src-template-single-event-js" */),
  "component---src-template-single-the-walk-js": () => import("./../../../src/template/single-the-walk.js" /* webpackChunkName: "component---src-template-single-the-walk-js" */)
}

